import { useState, useEffect, useRef } from "react";
import axios from "axios";
import Filter from "../../components/Filter";
import Card from "../../components/Card";
import "./Home.css";
import useAuth from "../../Hooks/useAuth";
import Reklamlar from "../../components/Reklamlar";
import { Helmet } from "react-helmet-async";
import InfiniteScroll from "react-infinite-scroll-component";
import ClipLoader from "react-spinners/ClipLoader";
export default function Home() {
  const [search, setSearch] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [regionId, setRegionId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [statusId, setStatusId] = useState("");
  const [isOnlineId, setIsOnlineId] = useState("");
  const controller = useRef();
  const { auth } = useAuth();
  const [announcements, setAnnouncements] = useState([]);
  const [vipAnnouncements, setVipannouncments] = useState();
  const [numAnnouncements] = useState(10);
  const [ofset, setOfset] = useState(0); // Make sure to update the offset state correctly
  const [loading, setLoading] = useState(false); // Optional: to prevent multiple requests
  const [hasMore, setHasMore] = useState(true);


  const GetAnnouncements = async () => {
    if (loading || !hasMore) return; // Prevent fetching if already loading or no more data
  
    setLoading(true);
  
    controller.current = new AbortController();
    const signal = controller?.current?.signal;
  
    if (auth.user !== undefined) {
      try {
        const response = await axios.get(
          `https://api.kursline.az/GetAnnouncements?limit=${numAnnouncements}&offset=${ofset}&RegionId=${regionId}&CategoryId=${categoryId}&search=${search}&minPrice=${minPrice}&maxPrice=${maxPrice}&statusId=${statusId}&isOnlineId=${isOnlineId}&userId=${auth.user.userInfo.id}`,
          { signal }
        );
  
        setVipannouncments(response.data.vipAnnouncements);
  
        if (response.data.announcemenets.length > 0) {
          setAnnouncements((prev) => [
            ...prev,
            ...response.data.announcemenets,
          ]);
          setOfset((prev) => prev + numAnnouncements); // Increment offset
        } else {
          setHasMore(false); // No more announcements to load
        }
      } catch (error) {
        // Handle error
      } finally {
        setLoading(false);
      }
    } else {
      try {
        const response = await axios.get(
          `https://api.kursline.az/GetAnnouncements?limit=${numAnnouncements}&offset=${ofset}&RegionId=${regionId}&CategoryId=${categoryId}&search=${search}&minPrice=${minPrice}&maxPrice=${maxPrice}&statusId=${statusId}&isOnlineId=${isOnlineId}&userId=0`,
          { signal }
        );
  
        setVipannouncments(response.data.vipAnnouncements);
  
        if (response.data.announcemenets.length > 0) {
          setAnnouncements((prev) => [
            ...prev,
            ...response.data.announcemenets,
          ]);
          setOfset((prev) => prev + numAnnouncements); // Increment offset
        } else {
          setHasMore(false); // No more announcements to load
        }
      } catch (error) {
        // Handle error
      } finally {
        setLoading(false);
      }
    }
  };
  const GetAnons = () => {
    if (auth.user !== undefined) {
      axios
        .get(
          `https://api.kursline.az/GetAnnouncements?limit=${numAnnouncements}&offset=${ofset}&RegionId=${regionId}&CategoryId=${categoryId}&search=${search}&minPrice=${minPrice}&maxPrice=${maxPrice}&statusId=${statusId}&isOnlineId=${isOnlineId}&userId=${auth.user.userInfo.id}`
        )
        .then(response => {
          setVipannouncments(response.data.vipAnnouncements);
          setAnnouncements(response.data.announcemenets);
        })
        .catch(error => {
          // console.log(error);
        });
    } else {
      axios
        .get(
          `https://api.kursline.az/GetAnnouncements?limit=${numAnnouncements}&offset=${ofset}&RegionId=${regionId}&CategoryId=${categoryId}&search=${search}&minPrice=${minPrice}&maxPrice=${maxPrice}&statusId=${statusId}&isOnlineId=${isOnlineId}&userId=${0}`
        )
        .then(response => {
          setVipannouncments(response.data.vipAnnouncements);
          setAnnouncements(response.data.announcemenets);
        })
        .catch(error => {
          // console.log(error);
        });
    }
  };
  const sifirla = () => {
    setOfset(0);
    setSearch("");
    setMinPrice("");
    setMaxPrice("");
    setRegionId("");
    setCategoryId("");
    setStatusId("");
    setIsOnlineId("");
    GetAnons();
  };

  useEffect(() => {
    GetAnnouncements();
  }, []);
  //scroll event listener
  // const handelInfiniteScroll = async () => {
  //   try {
  //     if (
  //       window.innerHeight + document.documentElement.scrollTop + 1 >=
  //       document.documentElement.scrollHeight
  //     ) {
  //       setOfset(prev => prev + 50);
  //     }
  //   } catch (error) {
  //     // console.log(error);
  //   }
  // };
  // useEffect(() => {
  //   window.addEventListener("scroll", handelInfiniteScroll);
  //   return () => window.removeEventListener("scroll", handelInfiniteScroll);
  // }, []);

  return (
    <div >
      <Helmet>
        <title>Kursline - kurs elanları</title>
        <meta
          name="description"
          content="kurs elanları,kurslar, tədris, online kurslar, offline kurslar, IT, developer, proqramlaşdırma, programming, xarici dil, ingilis dili, rus dili"
        />
        <link rel="canonical" href="https://kursline.az/" />
      </Helmet>
      <div className="home-container-fluid">
        <Filter
          setAnnouncements={setAnnouncements}
          setCategoryId={setCategoryId}
          categoryId={categoryId}
          setMaxPrice={setMaxPrice}
          maxPrice={maxPrice}
          setMinPrice={setMinPrice}
          minPrice={minPrice}
          setIsOnlineId={setIsOnlineId}
          isOnlineId={isOnlineId}
          setSearch={setSearch}
          search={search}
          setRegionId={setRegionId}
          regionId={regionId}
          setStatusId={setStatusId}
          statusId={statusId}
          setOfset={setOfset}
          sifirla={sifirla}
        />
      </div>
      <div className="nullzoneisvip">
        {" "}
        <Reklamlar />
      </div>
      {/* <hr
        style={{
          border: "none",
          width: "100%",
          height: "2px",
          backgroundColor: "green",
        }}
      /> */}
      <div className="home-main-body">
        <div className="home-reklam-cont"></div>
        <div className="home-elanlar-cont">
          {" "}
          <div className="isvip">
            <div className="vip">
              <h3>VIP Elanlar</h3>
            </div>
          </div>
          <div className="cards">
            {vipAnnouncements &&
              vipAnnouncements.map(announcement => (
                <Card key={announcement.id} announcement={announcement} />
              ))}
          </div>
          <div className="isvip mt-3">
            <div className="vip">
              <h3>Son Elanlar</h3>
            </div>
          </div>
          <div className="cards" >
         {announcements?.length > 0 ?
         <InfiniteScroll
         dataLength={announcements?.length}
         next={GetAnnouncements}
         hasMore={hasMore}
         inverse={false}
       loader={<div style={{width:"100%",display:"flex",justifyContent:"center"}}>
         <ClipLoader color="Green"/>
        </div>
      }
       className={"cards"}
      //  scrollableTarget="scrollableDiv"
      >
    {announcements &&
       announcements.map((announcement) => (
        <Card key={announcement.id} announcement={announcement} />
      ))}
   </InfiniteScroll>  : 
         <div style={{width:"100%",display:"flex",justifyContent:"center"}}>
           <ClipLoader color="Green"/>
          </div>
         }
        
            {/* {announcements &&
              announcements.map(announcement => (
                <Card key={announcement.id} announcement={announcement} />
              ))} */}
          </div>
        </div>
        <div className="home-reklam-cont"></div>
      </div>
    </div>
  );
}
