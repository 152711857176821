import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import useAuth from "./Hooks/useAuth";
import Home from "./pages/Home/Home";
import Blog from "./pages/Blog/Blog";
import NewAnnouncement from "./pages/AnnounCements/NewAnnouncement";
import Categories from "./pages/Admin/Categories";
import Post from "./pages/Blog/Post";
import Layout from "./components/layout/Layout";
import Page404 from "./components/404";
import AnnouncementDetails from "./pages/AnnounCements/AnnouncementDetails";
import Login from "./pages/Login/login";
import UserProfile from "./pages/User/UserProfile";
import BusinessProfile from "./pages/Business/BusinessProfile";
import CourseProfile from "./pages/Course/CourseProfile";
import RequiredAuth from "./RequiredAuth/RequiredAuth";
import UserAnnouncements from "./pages/UserAnnouncements/UserAnnouncements";
import EditAnnouncement from "./pages/AnnounCements/EditAnnouncement";
import Favorites from "./pages/Favorites/Favorites";
import Vip from "./pages/Vips/vip";
import Irelicek from "./pages/Irelicek/irelicek";
import Admin from "./pages/Admin/Admin";
import AdminLayout from "./components/layout/Layout2";
import DetailElan from "./pages/Admin/DetailElan";
import Companies from "./pages/Admin/Companies";
import AdminMessage from "./pages/Admin/AdminMessage";
import MyAnnouncments from "./pages/User/MyAnnouncments";
import PaymentSucces from "./pages/Vips/paymentSucces";
import PaymentError from "./pages/Vips/paymentError";
import UpdateUsertoBisnes from "./pages/User/UpdateUsertoBisnes";
import Reklamlar from "./pages/Admin/Reklamlar";
import Orders from "./pages/Admin/Orders";
import Contact from "./components/Contact";
import CommanView from "./components/CommanView";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Commentler from "./pages/Admin/Commentler";
import AddAnnouncement from "./pages/AnnounCements/AddAnnouncement";
import { HelmetProvider } from "react-helmet-async";
import Roles from "./components/Roles/Roles";
import "./App.css";
import About from "./components/Roles/About";
import UserRole from "./components/Roles/UserRole";
import useLocalStorage from "./Hooks/useLocalStorage";
import Router from "./routes/Router";
function App() {
  const routing = useRoutes(Router);
  const [local, setLocal] = useLocalStorage("Auth", null);
  const { auth, setAuth } = useAuth();
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  useEffect(() => {
    if (local !== null) {
      setAuth(local);
    }
  }, []);

  useEffect(() => {
    if (!isPopupVisible && window.innerWidth < 768) {
      setIsPopupVisible(true);
    }
  }, []);

  const handlePopupClose = () => {
    setIsPopupVisible(false);
  };

  const ROLES = {
    user: 3,
    company: 2,
    kurs: 1,
    admin: 4,
  };

  return (
    <div>
      {isPopupVisible && (
        <div style={popupStyle}>
          <img
            className="mobilLogo"
            style={{ width: "60px", height: "50px" }}
            src={"/kurlinelog.jpg"}
            alt=""
          />
          <div style={{ width: "80%", textAlign: "center" }}>
            <span style={{ fontSize: "14px" }}>
              Mobil tətbiqimizi buradan yükləyin:
            </span>
            &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
            {"  "}
            <button style={popupBtn}>
              <a
                target="_blank"
                style={{ color: "white" }}
                href="https://play.google.com/store/apps/details?id=com.app.kurslinemobileapp"
              >
                yüklə
              </a>
            </button>
          </div>
          <button style={closeButtonStyle} onClick={handlePopupClose}>
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
      )}
      <div
        className="app-content"
        style={{ paddingTop: isPopupVisible ? "70px" : "0" }}
      >
        <HelmetProvider>{routing}</HelmetProvider>
      </div>
    </div>
  );
}

const popupStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "70px",
  backgroundColor: "white",
  color: "#721c24",
  padding: "10px",
  textAlign: "center",
  zIndex: 1000,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};
const popupBtn = {
  border: "none",
  fontSize: "12px",
  padding: "4px 12px",
  borderRadius: "7px",
  backgroundColor: "green",
  color: "white",
};

const closeButtonStyle = {
  backgroundColor: "white",
  border: "none",
  fontSize: "16px",
  cursor: "pointer",
  color: "#721c24",
  padding: "1px",
};

export default App;
