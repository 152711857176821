import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import "../Course/CourseProfile.css";
import Card from "../../components/Card";
import { Helmet } from "react-helmet-async";
import imggg from "../../images/businessreg.jpg"
// import Card from '../../components/Card';

function Post() {
  const { id } = useParams();
  const [company, setCompany] = useState();
  console.log(id);

  useEffect(() => {
    axios
      .get(`https://api.kursline.az/GetCompany/${id}`)
      .then(res => {
        console.log(res.data);
        setCompany(res.data[0]);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);
  return (
    <div className="courseProfileFirstMain">
      <Helmet>
        <title>Kursline - kurs elanlari</title>
        <meta
          name="description"
          content="kurs elanları,kurslar, tədris, online kurslar, offline kurslar, IT, developer, proqramlaşdırma, programming, xarici dil, ingilis dili, rus dili"
        />
        <meta
          name="Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt"
          content="Kursline.az, Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt. Öz kursunuzu bizdə kəşf edin."
        />
        <meta
          name="keywords"
          content="kurs elanları,kurslar, tədris, online kurslar, offline kurslar, IT, developer, proqramlaşdırma, programming, xarici dil, ingilis dili, rus dili"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Kursline.az komandası" />
        <meta
          name="og:title"
          property="og:title"
          content="Kursline.az - Kurs Elanları, tədris fürsətləri"
        />
        <meta
          name="og:description"
          property="og:description"
          content="Kursline.az, Fərqli-fərqli kursların elanlarının birləşdiyi veb sayt. Öz kursunuzu bizdə kəşf edin."
        />
        <meta
          name="og:image"
          property="og:image"
          content="https://www.kursline.az/kursline (1).png"
        />
        <meta
          name="og:url"
          property="og:url"
          content="https://www.kursline.az"
        />
        <meta
          name="og:site_name"
          property="og:site_name"
          content="Kursline.az"
        />
      </Helmet>
      <div className="courseProfileMain">
        <div className="courseProfileLeft">
          <h2>
            {company?.companyName ? company?.companyName : "Ad qeyd edilmiyib"}
          </h2>
          <h4>{company?.companyCategory}</h4>
          <h4 className="h4iki">Web programming</h4>
          <p>
            <i className="fa-solid fa-phone"></i>&nbsp;
            <span> {company?.companyPhone}</span>
          </p>
          <p>
            <i className="fa-sharp fa-solid fa-location-dot"></i>&nbsp;
            <span>
              {company?.companyRegion
                ? company?.companyRegion
                : "Region qeyd edilmiyib"}
            </span>
          </p>
          <hr />
        </div>

        <div className="courseProfileRight">
          <img
            src={company?.companyImage ??imggg}
            alt={`kursline,kurs, kurslar ,hazirliq`}
          />
          <div>
            <span>Elanlar:{company?.companyAnnouncementCount}</span>
            <span>Baxış sayı: {company?.companyAnnouncementsView}</span>
          </div>
        </div>
      </div>
      {company?.announcements?.filter(x => x.isVIP === true).lenght > 0 ? (
        <div className="courseProfileVip">
          <div className="vipHeader">VIP Elanlar</div>
          <div className="vipBody cards">
            {company?.announcements
              ?.filter(x => x.isVIP === true)
              .map(announcement => (
                <Card key={announcement.id} announcement={announcement} />
              ))}
          </div>
        </div>
      ) : null}
      <div className="courseProfileVip">
        <div className="lastHeader">Ən son Elanlar</div>
        <div className="vipBody cards">
          {company?.announcements
            ?.filter(x => x.isVIP === false)
            .map(announcement => (
              <Card key={announcement.id} announcement={announcement} />
            ))}
        </div>
      </div>
    </div>
  );
}

export default Post;
