import { Link, useNavigate } from "react-router-dom";
import "./Footer.css";
import useAuth from "../../Hooks/useAuth";
import { useEffect, useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";

export default function Footer() {
  const { auth, setAuth } = useAuth();
  const [mobactiv, setmobactiv] = useState(1);
  const [mdal, setmdal] = useState(false);
  const navigate = useNavigate();

  const handleLogOut = () => {
    Swal.fire({
      title: "Əminsiniz?",
      text: "Çıxış etmək istədiyinizdən əminsiniz?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Bəli",
      cancelButtonText: "Xeyr",
    }).then(result => {
      if (result.isConfirmed) {
        setAuth("");
        localStorage.clear();
        navigate("/", { replace: true });
        setmdal(false);
      }
    });
  };

  const [linkTo, setlinkTo] = useState("/login");

  const handleItemClick = index => {
    if (mobactiv === index) {
      // If the clicked item is already active, deactivate it
      setmobactiv(null);
      if (index === 4) {
        setmdal(false);
        // setmdal(true);
      }
    } else {
      // If a different item is active, deactivate it and activate the clicked item
      if (index === 4) {
        setmdal(true);
      } else {
        setmdal(false);
      }
      setmobactiv(index);
    }
  };

  useEffect(() => {
    if (auth.roles === 2 || auth.roles === 1 || auth.roles === 4) {
      setlinkTo("/CompanyProfile");
    } else if (auth.roles === 3) {
      setlinkTo("/UserProfile");
    }
  }, [auth?.roles]);

  const DeleteProfil = async () => {
    Swal.fire({
      title: "Əminsiniz?",
      text: "Hesabınızı silmək istədiyinizdən əminsiniz? Sildikdən sonra geri qaytara bilməzsiniz!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Bəli",
      cancelButtonText: "Xeyr",
    }).then(result => {
      if (result.isConfirmed) {
        try {
          axios
            .post(
              `https://api.kursline.az/User/DeleteUser?id=${auth.user.userInfo.id}`,
              {
                headers: { Authorization: `Bearer ${auth.user.accsessToken}` },
              }
            )
            .then(function (res) {
              if (res.status === 200) {
                if (res.data) {
                  setAuth("");
                  localStorage.clear();
                  navigate("/home", { replace: true });
                }
              } else {
                console.log(res);
              }
              setmdal(false);
            })
            .catch(function (err) {
              console.log(err);
            });
        } catch (error) {
          console.log("error");
        }
      }
    });
  };

  return (
    <div className="footer">
      <div className="main-footer">
        <div className="left-footer">
          <ul>
            <li>kursline.az &nbsp;|</li>
            <Link to="/PrivacyPolicy">
              <li>&nbsp; Privacy & Policy</li>
            </Link>
            /
            <Link to="/UserRole">
              <li>&nbsp; İstifadəçi şərtləri</li>
            </Link>
            /
            <Link to="/Roles">
              <li>&nbsp; Qaydalar</li>
            </Link>
          </ul>
        </div>
        <div className="right-footer">
          <div>
            <Link to="/contact">
              <i
                className="fa-solid fa-envelope"
                style={{ fontSize: "20px" }}
              ></i>{" "}
            </Link>
          </div>
          <div className="social-icons">
            <Link
              to={
                "https://www.facebook.com/profile.php?id=100063797953572&locale=az_AZ"
              }
            >
              <i className="fa-brands fa-facebook"></i>
            </Link>
            <Link to={"https://www.instagram.com/kursline.az/"}>
              {" "}
              <i className="fa-brands fa-instagram"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className="lower-footer">
        <p>
          Created and designed by
          <a href="https://www.aimtech.az/">AimTech</a>
        </p>
      </div>
      <div className="mob-menu">
        <div className="mob-items">
          <div className="mob-item">
            <Link
              to="/"
              onClick={() => handleItemClick(1)}
              className={mobactiv === 1 ? "mob-activ" : ""}
            >
              <i className="fa-solid fa-house"></i>
            </Link>
          </div>
          <div className="mob-item">
            <Link
              to={linkTo}
              onClick={() => handleItemClick(2)}
              className={mobactiv === 2 ? "mob-activ" : ""}
            >
              <i className="fa-solid fa-user"></i>
            </Link>
          </div>
          <div className="mob-item" style={{ position: "relative" }}>
            {auth && auth?.user ? (
              <Link
                to="/newannouncement"
                onClick={e => {
                  setmdal(false);
                  setmobactiv(0);
                }}
                className="mob-addelan"
              >
                <i className="fa-solid fa-plus"></i>
              </Link>
            ) : (
              <Link
                to="/Elan-əlavə-et"
                onClick={e => {
                  setmdal(false);
                  setmobactiv(0);
                }}
                className="mob-addelan"
              >
                <i className="fa-solid fa-plus"></i>
              </Link>
            )}
          </div>
          <div className="mob-item">
            <Link
              to={auth && auth?.user ? "/MyFavorites" : "/login"}
              onClick={() => handleItemClick(3)}
              className={mobactiv === 3 ? "mob-activ" : ""}
            >
              <i className="fa-regular fa-heart"></i>
            </Link>
          </div>
          <div className="mob-item">
            <Link
              onClick={() => handleItemClick(4)}
              className={mobactiv === 4 ? "mob-activ" : ""}
            >
              <i className="fa-solid fa-gear"></i>
            </Link>
          </div>
        </div>
      </div>
      <div className={mdal ? "modal-aktiv" : "mob-modal"}>
        <div className="md-mobmain">
          <div className="md-mobhead">
            <h2>Tənzimləmələr</h2>
          </div>

          <div className="md-mobitems" style={{ marginTop: "1rem" }}>
            <div className="md-mobitem ">
              <Link
                to={linkTo}
                style={{ marginLeft: "0px", color: "black" }}
                onClick={() => handleItemClick(2)}
              >
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.5 22.375C11.0833 23.2083 12.5833 23.625 14 23.625C15.4167 23.625 16.8958 23.2083 18.4375 22.375C20.0208 21.5417 21.2083 20.5208 22 19.3125C21.9583 18.1458 21.0208 17.1667 19.1875 16.375C17.3542 15.5833 15.625 15.1875 14 15.1875C12.375 15.1875 10.6458 15.5833 8.8125 16.375C6.97917 17.125 6.04167 18.1042 6 19.3125C6.79167 20.5208 7.95833 21.5417 9.5 22.375ZM16.8125 5.875C16.0208 5.08333 15.0833 4.6875 14 4.6875C12.9167 4.6875 11.9792 5.08333 11.1875 5.875C10.3958 6.66667 10 7.60417 10 8.6875C10 9.77083 10.3958 10.7083 11.1875 11.5C11.9792 12.2917 12.9167 12.6875 14 12.6875C15.0833 12.6875 16.0208 12.2917 16.8125 11.5C17.6042 10.7083 18 9.77083 18 8.6875C18 7.60417 17.6042 6.66667 16.8125 5.875ZM4.5625 4.625C7.1875 2 10.3333 0.6875 14 0.6875C17.6667 0.6875 20.7917 2 23.375 4.625C26 7.20833 27.3125 10.3333 27.3125 14C27.3125 17.6667 26 20.8125 23.375 23.4375C20.7917 26.0208 17.6667 27.3125 14 27.3125C10.3333 27.3125 7.1875 26.0208 4.5625 23.4375C1.97917 20.8125 0.6875 17.6667 0.6875 14C0.6875 10.3333 1.97917 7.20833 4.5625 4.625Z"
                    fill="#78BFDB"
                  />
                </svg>
                &nbsp; Mənim Hesabım
              </Link>
            </div>

            <hr />

            <div className="md-mobitem">
              <Link
                to="/contact"
                onClick={() => handleItemClick(4)}
                style={{ margin: "0", color: "black" }}
              >
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.6875 12.6875V10H18V12.6875H20.6875ZM15.3125 12.6875V10H12.6875V12.6875H15.3125ZM10 12.6875V10H7.3125V12.6875H10ZM24.6875 0.6875C25.3958 0.6875 26 0.958333 26.5 1.5C27.0417 2 27.3125 2.60417 27.3125 3.3125V19.3125C27.3125 20.0208 27.0417 20.6458 26.5 21.1875C26 21.7292 25.3958 22 24.6875 22H6L0.6875 27.3125V3.3125C0.6875 2.60417 0.9375 2 1.4375 1.5C1.97917 0.958333 2.60417 0.6875 3.3125 0.6875H24.6875Z"
                    fill="#78BFDB"
                  />
                </svg>
                &nbsp;Bizimlə əlaqə
              </Link>
            </div>
            <hr />

            <div className="md-mobitem">
              <Link
                to="/UserRole"
                onClick={e => setmdal(false)}
                style={{ margin: "0", color: "black", cursor: "pointer" }}
              >
                &nbsp;Istifadəçi şərtləri
              </Link>
            </div>
            <hr />
            <div className="md-mobitem">
              <Link
                to="/PrivacyPolicy"
                onClick={e => setmdal(false)}
                style={{ margin: "0", color: "black", cursor: "pointer" }}
              >
                &nbsp;Məxfilik siyasəti
              </Link>
            </div>
            <hr />
            <div className="md-mobitem">
              <Link
                to="/Roles"
                onClick={e => {
                  setmdal(false);
                  setmobactiv(0);
                }}
                style={{ margin: "0", color: "black", cursor: "pointer" }}
              >
                &nbsp;Qaydalar
              </Link>
            </div>
            <hr />
            <div className="md-mobitem">
              <Link
                to="/About"
                onClick={e => {
                  setmdal(false);
                  setmobactiv(0);
                }}
                style={{ margin: "0", color: "black", cursor: "pointer" }}
              >
                {/* <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  width="30px"
                  height="30px"
                >
                  <path
                    fill="#2196f3"
                    d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"
                  />
                  <path
                    fill="#fff"
                    d="M22 22h4v11h-4V22zM26.5 16.5c0 1.379-1.121 2.5-2.5 2.5s-2.5-1.121-2.5-2.5S22.621 14 24 14 26.5 15.121 26.5 16.5z"
                  />
                </svg> */}
                &nbsp;Haqqımızda
              </Link>
            </div>
            <hr />
            {auth && auth?.user ? (
              <div
                className="md-mobitem"
                style={{ cursor: "pointer" }}
                onClick={e => DeleteProfil()}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 100 100"
                  width="30px"
                  height="30px"
                >
                  <path
                    fill="#f37e98"
                    d="M25,30l3.645,47.383C28.845,79.988,31.017,82,33.63,82h32.74c2.613,0,4.785-2.012,4.985-4.617L75,30"
                  />
                  <path
                    fill="#f15b6c"
                    d="M65 38v35c0 1.65-1.35 3-3 3s-3-1.35-3-3V38c0-1.65 1.35-3 3-3S65 36.35 65 38zM53 38v35c0 1.65-1.35 3-3 3s-3-1.35-3-3V38c0-1.65 1.35-3 3-3S53 36.35 53 38zM41 38v35c0 1.65-1.35 3-3 3s-3-1.35-3-3V38c0-1.65 1.35-3 3-3S41 36.35 41 38zM77 24h-4l-1.835-3.058C70.442 19.737 69.14 19 67.735 19h-35.47c-1.405 0-2.707.737-3.43 1.942L27 24h-4c-1.657 0-3 1.343-3 3s1.343 3 3 3h54c1.657 0 3-1.343 3-3S78.657 24 77 24z"
                  />
                  <path
                    fill="#1f212b"
                    d="M66.37 83H33.63c-3.116 0-5.744-2.434-5.982-5.54l-3.645-47.383 1.994-.154 3.645 47.384C29.801 79.378 31.553 81 33.63 81H66.37c2.077 0 3.829-1.622 3.988-3.692l3.645-47.385 1.994.154-3.645 47.384C72.113 80.566 69.485 83 66.37 83zM56 20c-.552 0-1-.447-1-1v-3c0-.552-.449-1-1-1h-8c-.551 0-1 .448-1 1v3c0 .553-.448 1-1 1s-1-.447-1-1v-3c0-1.654 1.346-3 3-3h8c1.654 0 3 1.346 3 3v3C57 19.553 56.552 20 56 20z"
                  />
                  <path
                    fill="#1f212b"
                    d="M77,31H23c-2.206,0-4-1.794-4-4s1.794-4,4-4h3.434l1.543-2.572C28.875,18.931,30.518,18,32.265,18h35.471c1.747,0,3.389,0.931,4.287,2.428L73.566,23H77c2.206,0,4,1.794,4,4S79.206,31,77,31z M23,25c-1.103,0-2,0.897-2,2s0.897,2,2,2h54c1.103,0,2-0.897,2-2s-0.897-2-2-2h-4c-0.351,0-0.677-0.185-0.857-0.485l-1.835-3.058C69.769,20.559,68.783,20,67.735,20H32.265c-1.048,0-2.033,0.559-2.572,1.457l-1.835,3.058C27.677,24.815,27.351,25,27,25H23z"
                  />
                  <path
                    fill="#1f212b"
                    d="M61.5 25h-36c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h36c.276 0 .5.224.5.5S61.776 25 61.5 25zM73.5 25h-5c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h5c.276 0 .5.224.5.5S73.776 25 73.5 25zM66.5 25h-2c-.276 0-.5-.224-.5-.5s.224-.5.5-.5h2c.276 0 .5.224.5.5S66.776 25 66.5 25zM50 76c-1.654 0-3-1.346-3-3V38c0-1.654 1.346-3 3-3s3 1.346 3 3v25.5c0 .276-.224.5-.5.5S52 63.776 52 63.5V38c0-1.103-.897-2-2-2s-2 .897-2 2v35c0 1.103.897 2 2 2s2-.897 2-2v-3.5c0-.276.224-.5.5-.5s.5.224.5.5V73C53 74.654 51.654 76 50 76zM62 76c-1.654 0-3-1.346-3-3V47.5c0-.276.224-.5.5-.5s.5.224.5.5V73c0 1.103.897 2 2 2s2-.897 2-2V38c0-1.103-.897-2-2-2s-2 .897-2 2v1.5c0 .276-.224.5-.5.5S59 39.776 59 39.5V38c0-1.654 1.346-3 3-3s3 1.346 3 3v35C65 74.654 63.654 76 62 76z"
                  />
                  <path
                    fill="#1f212b"
                    d="M59.5 45c-.276 0-.5-.224-.5-.5v-2c0-.276.224-.5.5-.5s.5.224.5.5v2C60 44.776 59.776 45 59.5 45zM38 76c-1.654 0-3-1.346-3-3V38c0-1.654 1.346-3 3-3s3 1.346 3 3v35C41 74.654 39.654 76 38 76zM38 36c-1.103 0-2 .897-2 2v35c0 1.103.897 2 2 2s2-.897 2-2V38C40 36.897 39.103 36 38 36z"
                  />
                </svg>
                &nbsp;Hesabı sil
              </div>
            ) : (
              ""
            )}
            {auth && auth.user ? <hr /> : ""}
            {auth && auth.user ? (
              <div
                className="md-mobitem"
                style={{ cursor: "pointer" }}
                onClick={handleLogOut}
              >
                <svg
                  width="28"
                  height="24"
                  viewBox="0 0 28 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.3125 2.6875V21.3125H14V24H3.3125C2.60417 24 1.97917 23.7292 1.4375 23.1875C0.9375 22.6458 0.6875 22.0208 0.6875 21.3125V2.6875C0.6875 1.97917 0.9375 1.35417 1.4375 0.8125C1.97917 0.270833 2.60417 0 3.3125 0H14V2.6875H3.3125ZM20.6875 5.3125L27.3125 12L20.6875 18.6875L18.8125 16.75L22.25 13.3125H8.6875V10.6875H22.25L18.8125 7.1875L20.6875 5.3125Z"
                    fill="#78BFDB"
                  />
                </svg>
                &nbsp;Çıxış
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
